@font-face {font-family: 'Gunkan';src: url('../fonts/impactnieuw-2019.woff') format('woff');}
@font-face {font-family: 'Psavec';src: url('../fonts/westbury-signature.woff') format('woff');}

* {
    box-sizing: border-box;
}

body {
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen","Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
    font-size: 1.45vw;
    line-height: 1.28;
    margin: 0;
    text-rendering: optimizeLegibility;
    -webkit-font-smoothing: antialiased;

    @mixin media {
        font-size: 15px;
    } 

    // @media only screen and (min-width: 900px) {
    //     font-size: 18px;
    // }

    @media only screen and (min-width: 1500px) {
        font-size: 1.5vw;
    }

    // @media only screen and (maxx-width: 750px) {
    //     font-size: 18px;
    // }
}

html {
    scroll-behavior: smooth;
  }

a {
    text-decoration: none;
    color: inherit;
}
