.gallery {
    margin: $lh;
    margin-bottom: $lh * 3;  
    width: 50%;
    position: relative;
    left: 25%;
    transform: translate(-25%, 0);

    @mixin media {
        width: 90%;
        left: 0;
        transform: translate(0, 0);;
    }
} 

.main-carousel {
}

.flickity-viewport {
    border-radius: $lh;
    overflow: hidden;
    box-shadow: 0 0px 20px 5px rgba(255 ,255 ,255 ,.5);
}
.carousel-cell {
    //height: 50em;
    width: 100%;
    margin: 0;
    padding: 0;

    img {
        display: block;
        height: 100%;
        width: 100%;
    }
}

.dot {
    width: 4em;
    height: 4em;
}