.introduction {
    min-height: 35em;

    @mixin media {
        min-height: 30em;
    }
}

.int-tilte {
    font-family: Gunkan;
    font-weight: normal;
    font-size: 1.8em;
    line-height: 1.1;
    margin: 0;
    padding: $lh / 4;
    user-select: none;
    position: relative;
    z-index: 100;
    color: rgb(50,50,50);

    

    a {
        text-decoration: none;
        color: inherit;
        z-index: 100;
    }
}

.int-number {
    position: absolute;
    top: 0.2em;
    right: 0.2em;
    font-family: Psavec;
    font-size: 3.5em;
    //background: blue;
    user-select: none;
    z-index: 100; 

    @mixin media {
        display: none;
    }
}

.int-subtitle {
    font-family: Psavec;
    font-size: 3.5em;
    // position: absolute;
    // top: 1.2em;
    // left: 0.5em;
    user-select: none;
    //background: blue; 

    @mixin media {
        //top: 2.5em;
        font-size: 3em;
    }
}

.int-subtitle-big {
    font-size: 6em;
}

.int-subtitle-event {
    margin-left: 0.6em;
}

.int-us {
    font-family: Gunkan;
    font-size: 15em;
    position: relative;
    left: 50%;
    margin-top: 0.1em;
    transform: translate(-50%,0);
    //background: blue;
    display: inline-block;
    user-select: none;
    color: rgb(50,50,50);
    
    @mixin media {
        //top: 2.5em;
        font-size: 7.5em;
    }
}

//Page

.intro {
    // min-height: 33vh;
}
.event-section {
    padding-left: $lh / 2;
    padding-right: $lh / 2;
    margin-bottom: 0;
}

.two-columns {
    display: flex;
    flex-wrap: wrap;

    @mixin media {
        margin-top: $lh;
    }

}

.col-1 {
    flex: 0 0 33%;
    //background:  blue;
    //padding-right: $lh;

    @mixin media {
        flex: 0 0 100%;
    }

    p {
        margin: 0;
    }

}

.col-2 {
    flex: 0 0 66%;
    //background:  green;
    padding-left: $lh * 2;

    @mixin media {
        flex: 0 0 100%;
        padding-left: 0;
        margin-top: $lh * 2;
    }

    p {
        margin: 0;
    }
}

.programm {
    list-style-type: none;
    margin: 0;
    padding: 0;
    margin-top: $lh;    
}

.programm-link {
    display: flex;
    flex-wrap: wrap;
    p {
        display: inline-block;
        margin: 0;
        padding: 0;
    }
}

.programm-time {
    flex: 0 0 10%;

    @mixin media {
        flex: 0 0 15%;
    }
}

.programm-text {
    flex: 0 0 65%;
    padding-right: $lh;

    @mixin media {
        flex: 0 0 60%;
    }
}

.programm-type {
    flex: 0 0 25%;
    overflow: hidden;
    text-overflow: ellipsis;
}

.featured {
    column-count: 2;
 margin-top: $lh * 4;
 padding-bottom: $lh * 4;

 @mixin media {
    column-count: 1;
}

 p {
     margin-top: 0;
 }

 a::before {
    content: "🕸️";
  }
  a:hover {
      opacity: 0.5;
  }


}

.featured-section {
    break-inside: avoid;
    margin-bottom: $lh * 2;
}

.int-links {
    display: flex;
    flex-wrap: wrap;
    padding-bottom: $lh * 1
}

.int-block-hov:hover {
    background: #fffdc0;
    //color: white;
}

.int-block:hover {
    opacity: 0.5;
}

.blue:hover {
    background: #bddcef;
    //color: white;
}

.int-block {
    flex: 0 0 32%;
    box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.25);
    padding: $lh * 1.5;
    padding-left: $lh * 1.5;
    margin-left: $lh /2;
    margin-bottom: $lh /2;
    border-radius: 0.1em;
    position: relative;
    height: $lh * 11;

    @mixin media {
        height: $lh * 11;
        flex: 0 0 95%;
    }
}

.yellow {
    background:#ffff4c;
}

.blue {
    background: #00b3e6;
    //background: lightgray;
    //opacity: 0.5;
    //background: rgba(150,150,150,0.05);

    @mixin media {
        border-bottom: 1px solid black;
    }
}

.green {
    background: #05e800;
}

.pink {
    background: #ffd3c5;

    @mixin media {
        border-bottom: 1px solid black;
    }
}

.int-date {
    text-align: center;
    position: absolute;
    bottom: $lh * 1.5;
    left: 50%;
    transform: translate(-50%,0);
}

.programm-title {
    margin-top: $lh * 2;
}

.yellowish {
    background: #ffc822;
}