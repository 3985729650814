@import '_variables.scss';
@import '_mixins.scss';
@import '_flykity.scss';
@import '_default.scss';
@import '_introduction.scss';
@import '_footer.scss';
@import '_gallery.scss';

body {
    //background:#ffff4c;
    //background:#ffd3c5;
    background: #fea6d0;
}

main {
    // background:#ffff4c;
    // background: #ffc822;
    // background: #91b6bb;
    background: #d86779;
    margin: 0;
    min-height: 90vh;
}

.greenish {
    background: #91b6bb !important;
}

// .carousel-container {
//     position: relative;
//     width: 100%;
//     height: 0;
// 	display: block;
// }

// .carousel {
//     position: absolute;
//     top: 0;
//     left: 0;
//     width: 100%;
//     height: 100%;
//     display: block;
//     //box-shadow: 0px 0px 10px 5px rgba(0,0,0,0.1);
// }

// .carousel-cell {
//     width: 100%;
//     height: 100%;
//     margin-right: 10px;
//     position: relative;
// }

// .carousel-image {
//     position: absolute;
//     width: 100%;
//     height: 100%;
// }

.bullets {
    line-height: 1;
    //letter-spacing: -0.1em;
    font-size: 0;
    transform: translate(0, 1vw);
    
    a {
        font-size: 10vw;
        display: inline-block;
        text-decoration: none;
        cursor: pointer;
        user-select: none;
        height: 1em;
    }

    a:hover {
        border-bottom: $lh / 18 solid;
    }

    @mixin media {
        display: block;
        margin-bottom: $lh / 2;
    } 
}

.bullets-two {
    //background: red;
    text-align: right;
}

.js-active-bullet {
    // color: rgba(0,0,0,0.0) !important;
    // -webkit-text-stroke-width: 1px;
    // -webkit-text-stroke-color: black;
    border-bottom: $lh / 18 solid;
}



//Artists
.artist {
    width: 100%;
    //background: red;
}
.artist-list {
    width: 100%;
    //font-size: 0.8em; 
    //background: blue;

}

.rt-td {
    a {
        color: black;
    }
}

.ReactTable {
    font-size: 0.8em; 
}

canvas {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 0;
}

.emoji {
    position: absolute;
    //background: red;
    font-size: 3em;
    z-index: 500;
    user-select: none;
    pointer-events: none;
}

.emoji-container {
    //background: red;
}



.blink {
    animation: blink-animation 1s steps(5, start) infinite;
    -webkit-animation: blink-animation 1s steps(5, start) infinite;
  }
  @keyframes blink-animation {
    to {
      visibility: hidden;
    }
  }
  @-webkit-keyframes blink-animation {
    to {
      visibility: hidden;
    }
  }

  strong {
      font-family: gunkan;
      color: rgb(50,50,50);
      margin-right: 0.5em;
  }

  .superpink {
      background: #fea6d0;
  }

  .redish {
    background: #d86779;
  }


  .watchlive {

    
    width: 100vw;
    text-align: center;
    margin-bottom: $lh * 2;

    a {
        padding: $lh;
        padding-left: 1.2em;
        padding-right: $lh * 4;
        font-family: Gunkan;
        box-shadow: 0px 0px 10px 5px rgba(0,0,0,0.1);
        background:  #a4c9ce;
        border-radius: 0.1em;
    }
}


.livespot {
    display: inline-block;
    background: #ba0af5;
    width: 1.42em;
    height: 1.42em;
    border-radius:1000em;
    margin-right: 4em;
    animation: blinker 4s linear infinite;
    transform: translate(0, 0.38em);
}

@keyframes blinker {
    50% {
      opacity: 0;
    }
  }

